<template>
  <div style="color: #000" class="box my_warp">
    <h2 style="text-align: center; font-size: 17px; padding: 15px 0">
      网络文化经营内容自审管理制度
    </h2>
    &emsp;&emsp;为加强网络文化内容建设与管理，规范网络文化经营单位产品及 服务内容自审工作，增强本公司自主管理能力和自律责任，确保网络 文化健康、快速发展，根据《互联网文化管理暂行规定》,结合本单
    位网络文化建设与管理的实际情况和发展需要，特制定本制度。<br />
    &emsp;&emsp;根据文化部制定的《网络文化经营单位内容自审管理办法》的要 求，我公司挑选了三名员工，组成了我公司的自审部门。自审部门主 要负责自审工作的最后审核，包括对网络文化产品及服务的内容的审
    核签字，对网络文化产品及服务的内容合法性的准确判断及运行规范。自审人员要对其审核的网络文化产品及服务的内容认真负责。<br />
    &emsp;&emsp;1、自审人员的职责<br>
    &emsp;&emsp;&emsp;&emsp;1)掌握网络文化经营内容审核的政策法规和相关知识；<br>
    &emsp;&emsp;&emsp;&emsp;2)有较高的政治敏感性，能够把握审核尺度；<br>
    &emsp;&emsp;&emsp;&emsp;3)有强烈的责任心，能够勇于表达审核意见；<br>
    &emsp;&emsp;&emsp;&emsp;4)以最终保证本企业的产品和服务的合法性和合规性为目的，对在 自身工作中发现的违法违规的产品服务内容进行记录并提请终止，作
    出提交修改，督促修改，复审等审核意见，重大问题向相关部门报告；<br>
    &emsp;&emsp;&emsp;&emsp;5)负责保管审查记录；<br>
    &emsp;&emsp;&emsp;&emsp;6)对产品及服务内容的合法合规性不能准确判断的，可向文化行政管理部门申请行政指导；<br>
    &emsp;&emsp;&emsp;&emsp;7)每年应至少参加1次由文化行政管理部门组织的业务培训；<br>
    &emsp;&emsp;&emsp;&emsp;8)组织内部培训工作，对企业开发、运营人员等进行内容审查方面的培训。<br>
    &emsp;&emsp;2、自审流程<br>
    &emsp;&emsp;&emsp;&emsp;1)对公司网站发布的网络文化产品内容，在产品上线前对产品内容、音视频等进行审核，审核通过的产品才能够正式上线；<br>
    &emsp;&emsp;&emsp;&emsp;2)自审人员在产品正式上线之前，依据内容审查的相关规定，对产 品及其服务内容(包括宣传推广和活动策划)进行审查，对违法违规的内容进行记录，并签发初审意见；<br>
    &emsp;&emsp;&emsp;&emsp;3)对初审有问题的产品，退回进行修改，并对修改后的内容进行复查；<br>
    &emsp;&emsp;&emsp;&emsp;4)对内容的合法合规性不能准确判断的，应向文化行政管理部门申请行政指导；<br>
    &emsp;&emsp;&emsp;&emsp;5)对复查仍有问题的，应按照上述第2-4项规定的内容重新进行审核；<br>
    &emsp;&emsp;&emsp;&emsp;6)在产品上线前，对产品客户端、公司网站平台进行审查，合格后方可公测；<br>
    &emsp;&emsp;&emsp;&emsp;7)对审查完成的产品，自审人员提出同意上线的意见并签字确认，上交内容审查管理工作的主要负责人；<br>
    &emsp;&emsp;&emsp;&emsp;8)内容审查管理工作的主要负责人签字确认后，根据规定要求，向文化行政管理部门提交产品备案；<br>
    &emsp;&emsp;&emsp;&emsp;9)日常对产品和服务内容进行监督检查，包括产品版本更新后的内容、产品客户端、公司官网、产品官网、宣传推广和活动策划等各个方面和环节，发现问题提交检查意见，报本企业内容审查管理工作主要负责人；<br>
    &emsp;&emsp;&emsp;&emsp;10)自审人员的所有审查意见应归档留存，保存时间不少于两年。<br>
    &emsp;&emsp;3、审查标准<br>
    &emsp;&emsp;网络文化产品不得含有以下内容：<br>
    &emsp;&emsp;&emsp;&emsp;1)违反宪法规定的基本原则；<br>
    &emsp;&emsp;&emsp;&emsp;2)危害国家统一、主权或者领土完整的；<br>
    &emsp;&emsp;&emsp;&emsp;3)泄露国家秘密的、危害国家安全或者损害国家荣誉和利益的；<br>
    &emsp;&emsp;&emsp;&emsp;4)煽动民族仇恨、民族歧视、破坏民族团结，或者侵害民族风俗、习惯的；<br>
    &emsp;&emsp;&emsp;&emsp;5)宣扬邪教、迷信的；<br>
    &emsp;&emsp;&emsp;&emsp;6)散布谣言，扰乱社会秩序，破坏社会稳定的；<br>
    &emsp;&emsp;&emsp;&emsp;7)宣扬淫秽、色情、赌博、暴力、或者教唆犯罪的；<br>
    &emsp;&emsp;&emsp;&emsp;8)侮辱、诽谤他人，侵害他人合法权益的；<br>
    &emsp;&emsp;其中网络表演不得含有以下内容：<br>
    &emsp;&emsp;&emsp;&emsp;1)含有《互联网文化管理暂行规定》第十六条规定的禁止内容的；<br>
    &emsp;&emsp;&emsp;&emsp;2)表演方式恐怖、残忍、暴力、低俗，摧残表演者身心健康的；<br>
    &emsp;&emsp;&emsp;&emsp;3) 利用人体缺陷或者以展示人体变异等方式招徕用户的；<br>
    &emsp;&emsp;&emsp;&emsp;4) 以偷拍偷录等方式，侵害他人合法权益的；<br>
    &emsp;&emsp;&emsp;&emsp;5) 使用未取得文化行政部门内容审查批准文号或备案编号的网络游戏产品，进行网络游戏技法展示或解说的。<br>
    &emsp;&emsp;4、 责任追究<br>
    &emsp;&emsp;&emsp;&emsp;1)审查人员未能在审查过程中发现问题的，第一次予以口头警告，第二次罚款1000元，第三次罚款3000元并调离该岗位；有重大问题但是审查人员未能发现的，罚款3000元并直接调离该岗位。<br>
    &emsp;&emsp;&emsp;&emsp;2)公司自主经营的产品，审查人员发现问题后，开发人员拒不修改 的，对开发人员第一次予以书面警告；第二次罚款3000元并调离岗位。<br>
    &emsp;&emsp;&emsp;&emsp;3)第三方提供的网络文化产品内容，审查人员发现问题后，第三方拒不修改的，取消其合作资格，并由第三方根据合同赔偿。<br>
    &emsp;&emsp;&emsp;&emsp;4)运营人员在运营过程中发现问题，但是未通知审查人员的，而审查人员也未及时发现的，所有相关人员罚款1000元，累计满三次的人员予以调离岗位。<br>
    &emsp;&emsp;5、 自审人员名单<br>
    <div style="padding-left: 40px;padding-top: 10px;">
      <table border="1" width="40%" align="center">
        <tr>
          <td>自审人员</td>
          <td>身份证号</td>
          <td>职务</td>
          <td>职责</td>
        </tr>
        <tr>
          <td>刘峰</td>
          <td>500231199601286054</td>
          <td>自审小组组长</td>
          <td>监督自审小组执行公司内 容自审制度，对自审小组审核结果进行终审</td>
        </tr>
        <tr>
          <td>高万庆</td>
          <td>500101199008010459</td>
          <td>自审小组成员</td>
          <td>执行公司内容自审制度， 对网络文化产品内容进行复审</td>

        </tr>
        <tr>
          <td>罗安轩</td>
          <td>500229199410203410</td>
          <td>自审小组成员</td>
          <td>执行公司内容自审制度， 对网络文化产品内容进行初审</td>

        </tr>
      </table>
    </div>
    <br />

  </div>
</template>

<script>
export default {
  data() {
    return {
      COMPANY_NAME_All: process.env.VUE_APP_COMPANY_NAME_All,
      tableData: [{
        num: '500229199410203410',
        name: '罗安轩',
        address: '初审'
      }, {
        num: '500101199008010459',
        name: '高万庆',
        address: '初审'
      }, {
        num: '500231199601286054',
        name: '刘峰',
        address: '复审'
      }]
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  font-size: 12px;
}
</style>